import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import {
  Session,
  AuthChangeEvent,
  SignInWithPasswordCredentials,
  Provider,
  User,
} from '@supabase/supabase-js';

import { BehaviorSubject } from 'rxjs';

import { SupaUser } from '../admin/shared/supabase/database-extension.types';
import { SupabaseService } from '../admin/shared/supabase/supabase.service';
import { EmailSignUpCredentials } from './sign-up/sign-up-form/email-sign-up-credentials';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  router = inject(Router);

  isReady$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly supabaseService: SupabaseService,
    private readonly userService: UserService
  ) {
    this.authChanges((event, session) => {
      const user = this.getAuthUser(session?.user);

      if (event === 'SIGNED_IN' || event === 'INITIAL_SESSION') {
        this.userService.setUser(user);
      } else if (event === 'SIGNED_OUT') {
        this.userService.setUser(null);
      }

      if (user) {
        // kick off a request to get the user's role
        this.getUserRole(user.id).then(role => {
          userService.setUser({ ...user, user_type: role ?? 'standard' });
          this.isReady$.next(true);
        });
      }
    });
  }

  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabaseService.client.auth.onAuthStateChange(callback);
  }

  getSession() {
    return this.supabaseService.client.auth.getSession();
  }

  async getUserRole(user_id: string) {
    const { data: user_roles, error } = await this.supabaseService.client
      .from('user_roles')
      .select('*')
      .eq('user_id', user_id);

    if (error) {
      console.error(error);
      return null;
    }

    const { role } = user_roles.at(0) ?? { role: null };

    return role;
  }

  async getAuthToken() {
    const session = await this.getSession();
    return session.data.session?.access_token ?? null;
  }

  async signInWithPassword(creds: SignInWithPasswordCredentials) {
    const { data, error } = await this.supabaseService.client.auth.signInWithPassword(creds);
    if (error) {
      throw error;
    }

    return this.getAuthUser(data?.user);
  }

  signInWithProvider(provider: Provider, returnUrl?: string) {
    return this.supabaseService.client.auth.signInWithOAuth({
      provider: provider,
      options: { redirectTo: returnUrl },
    });
  }

  resetPassword(newPassword: string) {
    return this.supabaseService.client.auth.updateUser({
      password: newPassword,
    });
  }

  resetPasswordForEmail(email: string) {
    const redirectTo = new URL('/sign-in/reset-password', window.location.origin).toString();
    return this.supabaseService.client.auth.resetPasswordForEmail(email, {
      redirectTo,
    });
  }

  getAuthUser(user?: User) {
    if (!user) {
      return null;
    }

    return {
      first_name: user?.user_metadata?.['first_name'],
      last_name: user?.user_metadata?.['last_name'],
      company: user?.user_metadata?.['company'],
      id: user.id,
      email: user.email,
    } as SupaUser;
  }

  signUp(creds: EmailSignUpCredentials) {
    return this.supabaseService.client.auth.signUp(creds);
  }

  signOut() {
    return this.supabaseService.client.auth.signOut();
  }

  navigateOrRedirectToLoginIfNotLoggedIn(targetUrl: UrlTree) {
    if (!this.userService.user) {
      const returnUrl = targetUrl.toString();
      targetUrl = this.router.createUrlTree(['/sign-in'], { queryParams: { returnUrl } });
    }

    this.router.navigateByUrl(targetUrl);
  }
}
